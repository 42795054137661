import React from "react"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Mailchimp from "react-mailchimp-form"

import style from "./footer.module.scss"

const Footer = () => {
  // const [inputClicked, setInputClicked] = useState(false)
  const url =
    "https://andcampaignchs.us10.list-manage.com/subscribe/post?u=06b8fd32e81050697f2058f82&amp;id=3e05ff7e9f"
  return (
    <footer className={`${style.footer} black-bg white-color`}>
      <div className="wrapper flex caps">
        <div className={`${style.left} col inner`}>
          <img src="/social-justice-icon.png" />
          <p>(&) Charleston</p>
          <div className="flex">
            <a href="mailto:andcampaignchs@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a
              href="https://www.facebook.com/andcampaigncharleston"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </div>
        </div>
        <div className="col flex column justify-center">
          <p className={`${style.headline} text-md futura-font`}>
            Sign up for our newsletter
          </p>
          <p className={`${style.subheadline} text-base`}>
            Engage by staying up to date with the latest news and events.
          </p>
          <p
            className={`${style.label} caps text-xs`}
            // onClick={() => setInputClicked(!inputClicked)}
          >
            Email Address
          </p>
          <Mailchimp
            action={url}
            fields={[
              {
                name: "EMAIL",
                placeholder: "youremail@email.com",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "You must type in an e-mail.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "Sign Up",
            }}
          />
        </div>
      </div>
      <div className="center-text" style={{ marginTop: "50px" }}>
        <a
          href="https://andcampaign.org/"
          className={`${style.parent_url} caps text-sm`}
          target="_blank"
          rel="noopener noreferrer"
        >
          (&) CAMPAIGN
        </a>
      </div>
    </footer>
  )
}

export default Footer
