import { Link } from "gatsby"
import React from "react"

import style from "./header.module.scss"

const Header = () => (
  <header>
    <div className={style.wrapper}>
      <Link to="/">
        <img src={"/logo_black.png"} style={{ width: "75px" }} />
      </Link>
    </div>
  </header>
)

export default Header
