module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"andcampaignchs","accessToken":"MC5YdWctbkJBQUFCMEFkbXh3.77-9dkEV77-9CjHvv70077-9Ou-_vXVj77-977-9JCLvv73vv70q77-9HkRr77-977-977-9N--_ve-_ve-_vQ","schemas":{"homepage":{"Main":{"headline":{"type":"StructuredText","config":{"single":"heading1","label":"Headline"}},"subheadline":{"type":"StructuredText","config":{"single":"heading2","label":"Subheadline"}},"hero_youtube_video_id":{"type":"StructuredText","config":{"single":"paragraph","label":"Youtube Video ID"}},"hero_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Hero Image"}},"hero_image_2":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Hero Image 2"}},"cta_text":{"type":"StructuredText","config":{"single":"paragraph","label":"Call To Action Button Text","placeholder":"Listen (&) Learn"}},"cta_url":{"type":"Link","config":{"label":"Call to Action Button URL"}},"cta_text_2":{"type":"StructuredText","config":{"single":"paragraph","label":"Call to Action Button Text 2","placeholder":"Donate"}},"cta_url_2":{"type":"Link","config":{"label":"Call to Action Button URL 2"}}},"Mission":{"mission_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image"}},"body_copy":{"type":"StructuredText","config":{"multi":"paragraph, heading1, heading2, heading3, strong, em, hyperlink, list-item, o-list-item, o-list-item","allowTargetBlank":true,"label":"Body Copy"}}},"Leadership":{"people":{"type":"Group","config":{"fields":{"name":{"type":"StructuredText","config":{"single":"heading1","label":"Name"}},"headshot":{"type":"Link","config":{"select":"media","label":"Headshot"}},"bio":{"type":"StructuredText","config":{"multi":"paragraph, strong, em, hyperlink, list-item, o-list-item","allowTargetBlank":true,"label":"Bio"}}},"label":"People"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"eav3aof"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166954653-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
