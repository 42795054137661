import React, { useState, useEffect } from "react"
import axios from "axios"
import ContextConsumer from "./Context"
import Header from "./Header"
import Footer from "./Footer"
import Transition from "./Transition"

const PageWrap = props => {
  const { location } = props
  const [grams, setGrams] = useState([])
  const handle = "andcampaignchs"
  useEffect(() => {
    const instagramRegExp = new RegExp(
      /<script type="text\/javascript">window\._sharedData = (.*);<\/script>/
    )

    const fetchInstagramPhotos = async accountUrl => {
      const response = await axios.get(accountUrl)
      const json = JSON.parse(response.data.match(instagramRegExp)[1])
      const edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(
        0,
        5
      )
      // this.setState((prevState) => ({
      //   grams: [...prevState.grams, edges],
      // }));
      setGrams(grams => [...grams, edges])
    }
    fetchInstagramPhotos(`https://www.instagram.com/${handle}/`)
  }, [])
  return (
    <ContextConsumer>
      {({ data, set }) => {
        // console.log(data)
        // console.log(grams)
        const childrenWithProps = React.Children.map(
          props.children,
          (child, i) =>
            React.cloneElement(child, { ...data, set, grams, handle })
        )
        return (
          <div className={`container flex ${data.menuOpen ? "menu-open" : ""}`}>
            <main>
              <Header />
              <Transition location={location}>
                {childrenWithProps}
                <Footer />
              </Transition>
            </main>
            {/* <Menu /> */}
          </div>
        )
      }}
    </ContextConsumer>
  )
}

export default PageWrap
