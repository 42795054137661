/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { ContextProviderComponent, ContextConsumer } from "./Context"

import "../styles.scss"

import PageWrap from "./PageWrap"
import Header from "./Header"
import Menu from "./menu/Menu"
import Footer from "./Footer"

const Layout = ({ children, location }) => {
  // console.log(props)
  return (
    <ContextProviderComponent>
      <PageWrap children={children} location={location} />
    </ContextProviderComponent>
  )
}

export default Layout
